
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "worker-rates-skills-dropdown",
  setup() {
    const model = ref<string | number>("");
    const store = useStore();
    const skills = computed(() => store.getters["WorkerRatesModule/skills"]);
    onMounted(() => {
      store.dispatch("WorkerRatesModule/fetchSkills");
    });
    return {
      skills,
      model
    };
  }
});
