
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  watch,
  ref
} from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import SkillsDropdown from "@/components/worker-rates/misc/worker-rates-skills-dropdown.vue";
import WorkerRatesDataTable from "@/components/worker-rates/Data-table.vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import CurrenciesDropdown from "@/components/dropdown/CurrenciesDropdown.vue";
import * as Yup from "yup";

export default defineComponent({
  components: {
    Form,
    ErrorMessage,
    Field,
    CurrenciesDropdown,
    LoadingSpinner,
    BranchesDropdown,
    SkillsDropdown,
    WorkerRatesDataTable
  },
  name: "worker-rates-index",
  setup: function() {
    const store = useStore();
    const isLoading = computed(
      () => store.getters["WorkerRatesModule/loading"]
    );
    const currencyForm = ref();
    const validationSchema = Yup.object().shape({
      currencyId: Yup.number()
        .transform((value, originalValue) => {
          return originalValue == "" ? null : value;
        })
        .typeError("Currency is required.")
        .required("Currency is required.")
    });
    const loadingCurrency = ref<boolean>(false);
    const currencyId = ref<number | null>(null);
    const lastBranchId = ref<string | number>("");
    const hasChanges = ref<boolean>(false);
    const query = reactive({
      branchID: "" as number | string,
      skillID: "" as number | string
    });
    const fetchBranchCurrency = () => {
      loadingCurrency.value = true;
      store
        .dispatch("WorkerRatesModule/fetchBranch", query.branchID)
        .then(data => {
          loadingCurrency.value = false;
          currencyId.value = data.currency_id as number;
          currencyForm.value.setFieldValue("currencyId", data.currency_id);
        })
        .catch(err => {
          Swal.fire({
            toast: true,
            timer: 1500,
            title: err.message ? err.message : "Server error occurred",
            showCancelButton: false
          });
        });
    };
    const hasQuery = computed<boolean>(
      (): boolean => query.branchID != "" && query.skillID != ""
    );
    watch(
      query,
      () => {
        if (query.branchID != "") {
          if (query.branchID != lastBranchId.value) {
            currencyId.value = null;
            fetchBranchCurrency();
          }
          lastBranchId.value = query.branchID;
        }
        if (query.skillID != "" && query.branchID != "") {
          hasChanges.value = false;
          store.dispatch("WorkerRatesModule/fetchRates", {
            query: query,
            triggerLoad: true
          });
        } else {
          hasChanges.value = false;
        }
      },
      {
        deep: true
      }
    );
    const handleSaveRates = data => {
      currencyForm.value.validate().then(result => {
        if (result.valid) {
          const payload = {
            rates: data,
            currencyId: currencyId.value
          };
          store.dispatch("WorkerRatesModule/store", payload).then(message => {
            hasChanges.value = false;
            store.dispatch("WorkerRatesModule/fetchRates", {
              query: query,
              triggerLoad: false
            });
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "success",
              title: message,
              showConfirmButton: false,
              timer: 1000
            });
          });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
      });
    };

    function onClose(event) {
      if (hasChanges.value) {
        event.preventDefault();
        event.returnValue = "";
      }
    }

    window.addEventListener("beforeunload", onClose);
    onMounted(() => {
      store.dispatch("WorkerRatesModule/fetchLevels");
    });
    return {
      validationSchema,
      loadingCurrency,
      handleSaveRates,
      currencyForm,
      currencyId,
      hasChanges,
      isLoading,
      hasQuery,
      query
    };
  }
});
