<template>
  <div class="row gy-5 g-xl-8">
    <WorkerRatesIndex></WorkerRatesIndex>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import WorkerRatesIndex from "@/components/worker-rates/index.vue";

export default defineComponent({
  name: "worker-rates-view",
  components: {
    WorkerRatesIndex
  },
  mounted() {
    setCurrentPageTitle("Worker rates");
  }
});
</script>
